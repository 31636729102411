import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { loadCurrentUser } from "./containers/Auth/auth.slice";
import { Spin } from "antd";
import AppRoutes from "./app.routes";

function App() {
  // Load current user

  const dispatch = useDispatch();
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = () => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const authJSON = JSON.parse(auth);
      dispatch(loadCurrentUser(authJSON));
    }
    setIsUserLoaded(true);
  };

  return (
    <>
      {isUserLoaded ? (
        <AppRoutes />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin tip="Loading..." />
        </div>
      )}
    </>
  );
}

export default App;
