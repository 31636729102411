export const INACTIVE = "INACTIVE";
export const ACTIVE = "ACTIVE";
export const ERROR = "ERROR";

export const ADDED = "ADDED";

export const ALLOWED_ROLES = ["organization_l_d_manager", "user"];

export const MOBILE_BREAKPOINT_WIDTH = 480;

export const EXPLORE_OPPORTUNITIES = "EXPLORE_OPPORTUNITIES";
export const PLAN_AND_COMMIT = "PLAN_AND_COMMIT";
