import { createSlice } from "@reduxjs/toolkit";
import { INACTIVE, ERROR } from "../../app/constants";

const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    selectedUser: null,
    log: null,
    status: INACTIVE,
    error: null,
  },
  reducers: {
    saveSelectedEmployee: (state, action) => {
      state.status = INACTIVE;
      state.selectedUser = action.payload.selectedUser;
      state.log = action.payload.log;
      state.error = null;
    },
    saveError: (state, action) => {
      state.status = ERROR;
      state.selectedUser = null;
      state.log = null;
      state.error = action.payload;
    },
  },
});

export const { saveError, saveSelectedEmployee } = employeeSlice.actions;

export default employeeSlice.reducer;
