import { createSlice } from "@reduxjs/toolkit";
import { INACTIVE, ACTIVE, ERROR } from "../../app/constants";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    role: null,
    status: INACTIVE,
    error: null,
  },
  reducers: {
    requestLogin: (state) => {
      state.status = ACTIVE;
      state.error = null;
    },
    successLogin: (state, action) => {
      state.status = INACTIVE;
      state.user = action.payload.user;
      state.role = action.payload.role;
      state.error = null;
    },
    errorLogin: (state, action) => {
      state.status = ERROR;
      state.user = null;
      state.role = null;
      state.error = action.payload;
    },
    loadCurrentUser: (state, action) => {
      state.user = action.payload;
      state.role = action.payload.role;
    },
    logoutUser: (state) => {
      state.user = null;
      state.role = null;
      state.error = null;
      state.status = INACTIVE;
    },
  },
});

export const {
  requestLogin,
  successLogin,
  errorLogin,
  loadCurrentUser,
  logoutUser,
} = authSlice.actions;

export default authSlice.reducer;
