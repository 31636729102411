import { createSlice } from "@reduxjs/toolkit";
import { ADDED, INACTIVE } from "../../../app/constants";

const developSlice = createSlice({
  name: "develop",
  initialState: {
    exploreOpportunity: null,
    planAndCommit: null,
    learnOnTheGo: null,
    status: INACTIVE,
    logData: null,
  },
  reducers: {
    setExploreOpportunity: (state, action) => {
      state.exploreOpportunity = action.payload.exploreOpportunity;
      state.logData = action.payload.userLog;
      state.planAndCommit = action.payload.planAndCommit;
      state.learnOnTheGo = action.payload.learnOnTheGo;
      state.status = ADDED;
    },
  },
});

export const { setExploreOpportunity } = developSlice.actions;

export default developSlice.reducer;
