import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import CenterSpinner from "./components/CenterSpinner";
import { ProtectedRoute } from "./util/ProtectedRoute";

const HomePage = lazy(() => import("./containers/Landing/home"));
const Dashboard = lazy(() =>
  import("./containers/Dashboard/dashboard.container")
);
const AuthContainer = lazy(() => import("./containers/Auth/auth"));
const SharedReflection = lazy(() =>
  import("./containers/Reflection/SharedReflection")
);

const AppRoutes = () => (
  <Suspense fallback={<CenterSpinner spinning tip="Please wait..." />}>
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route exact path="/reflection/:permalink">
        <SharedReflection />
      </Route>
      <ProtectedRoute key="dashboard" path="/dashboard">
        <Dashboard />
      </ProtectedRoute>
      <Route exact path="/auth" key="authentication">
        <AuthContainer />
      </Route>
      {/* Not Found */}
      <Route render={() => <h1>Not Found</h1>} />
    </Switch>
  </Suspense>
);

export default AppRoutes;
